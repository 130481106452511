import pym from 'pym.js';

window.faktisk = window.faktisk || {};
window.faktisk.pymParents = {};

(() => {
    var nodes = document.getElementsByClassName('faktisk-poll-embed');

    for (var i = 0; i < nodes.length; i++) {
        var elementId = nodes[i].id;
        var url = nodes[i].getAttribute('data-url');

        if (elementId && !faktisk.pymParents[elementId]) {
            window.faktisk.pymParents[elementId] = new pym.Parent(
                elementId,
                url,
                {}
            );

            try {
                // fix for width on ios - https://github.com/ampproject/amphtml/issues/11133
                var frame = faktisk.pymParents[elementId].iframe;
                frame.style.maxWidth = '100%';
                frame.style.minWidth = '100%';
                frame.style.width = 0;
            } catch (err) {
                console.log(err);
            }
        }
    }
})();
